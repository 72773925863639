import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { INavData } from '@reflact/ngx-layout/lib/body-layout/sidebar-nav/sidebar-nav.component';
import { UserDisplayData } from '@reflact/ngx-layout/lib/body-layout/user-display/user-display.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/AuthService';
import { LoginService } from '../shared/login.service';
import { UseradminGuard } from '../shared/useradmin.guard';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  public navExpanded: boolean = false;
  public navItems: INavData[] = [];
  public loggedInUser: UserDisplayData;
  public mandants: string[] = [];
  public currentMandant = "";
  public tanCount = 0;
  public tanCreated = "";
  public tanNotify = false;

  @ViewChild("selfUserSettings") public selfUserSettings!: OverlayAsideComponent;
  public formFields: NgxForm.FormField[] = [
    { fieldName: "email", label: "Email", type: 'text', required: true, readonly: true },
    { fieldName: "name", label: "Name", type: 'text', required: true, readonly: true }
  ];

  constructor(public authService: AuthService, private toastr: ToastrService, public adminGuard: UseradminGuard, public loginService: LoginService, public overlayService: OverlayService, public router: Router) { }

  public async ngOnInit(): Promise<void> {
    await this.getUserInfo();
    this.loggedInUser = {
      name: this.authService.getUserObject().name
    }
    const isAdmin = this.adminGuard.canActivate();
    this.navItems.push(
      {
        name: "Räume",
        url: "/",
        icon: "ri-list-ordered"
      }
    )
    this.navItems.push(
      {
        name: "Recordings",
        url: "/recordings",
        icon: "ri-record-circle-line"
      }
    )
    if (isAdmin) {
      this.navItems.push(
        {
          name: "Globale Einstellungen",
          url: "/settings",
          icon: "ri-settings-3-line"
        }
      )
      this.navItems.push(
        {
          name: "Meine Lizenzen",
          url: "/license",
          icon: "ri-file-text-line"
        }
      )
      this.navItems.push(
        {
          name: "Nutzerverwaltung",
          url: "/useradmin",
          icon: "ri-user-star-fill"
        }
      )
      if (this.loginService.loggedInUser.short == "rag") {
        this.navItems.push(
          {
            name: "Servers & Licenses",
            url: "/management",
            icon: "ri-file-list-3-line"
          }
        )
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.overlayService.get("selected") == "selfuser") {
      this.openSelfUserSettings();
    }
  }

  public async getUserInfo() {
    (await this.loginService.getUserInfo()).subscribe((res: {
      currentshort: string;
      availableShorts: string[];
      tanListCreated: string;
      tanListValidEntryCount: number;
    }) => {
      this.currentMandant = res.currentshort;
      this.mandants = res.availableShorts;
      this.tanCreated = res.tanListCreated;
      this.tanCount = res.tanListValidEntryCount;
      if (this.tanCount < 6 && this.tanCreated != null) {
        this.tanNotify = true;
      }
    });
  }

  public async changeMandant(mandantId: string) {
    await this.loginService.selectMandant(mandantId);
    window.location.reload();
  }

  public async openSelfUserSettings() {
    await this.getUserInfo();
    this.selfUserSettings.setSelected({ "_id": "selfuser", "data": this.loginService.loggedInUser });
  }

  public async createTanList() {
    this.toastr.success("Eine TAN-Liste wird nun erstellt", "Bitte warten Sie einen Moment.");
    await this.loginService.generateTanList();
    await this.getUserInfo();
  }

  public async deleteTanList() {
    await this.loginService.deleteTanList();
    await this.getUserInfo();
  }
}
