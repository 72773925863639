import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Access,
  BBBMandantConf,
  BBBUser,
  BbbGuest,
  BbbRoom,
  BbbRoomCreate,
  EndResponse,
  FsPortalFile,
  IsMeetingRunningResponse,
  JoinInfo,
  KickRecordingEvent,
  PresentationFileMetadata
} from '@reflact/kick';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthService } from './shared/AuthService';
import { SocketService } from './shared/SocketService';

export type Recording = {
  _id: string;
  metadata: {
    roomId: string;
    roomName: string;
    autoDelete: boolean;
    aftershowPartyList: BbbGuest[];
  };
  uploadDate: Date;
};

@Injectable({
  providedIn: 'root',
})
export class BbbService {
  public rooms: BbbRoom[];
  public roomsMap: Map<string, BbbRoom> = new Map();
  public recordings: Recording[];
  public allRecordings: Recording[] = [];
  public recordingEvents: Map<string, KickRecordingEvent> = new Map();
  public accessToName: Map<Access, string> = new Map([
    ['public', 'Öffentlich'],
    ['password', 'Registrierte Benutzer + Gäste via Passwort'],
    ['password_individual', 'Registrierte Benutzer + Gäste via Passwort (individual)'],
    ['waiting_room', 'Registrierte Benutzer + Gäste einzeln Bestätigen'],
    ['internal', 'Nur Registrierte Benutzer']
  ]);
  public accessToIcon: Map<string, string> = new Map([
    ['public', 'ri-earth-line'],
    ['password', 'ri-lock-password-line'],
    ['password_individual', 'ri-lock-password-fill'],
    ['waiting_room', 'ri-global-line'],
    ['internal', 'ri-group-2-fill'],
    ['internal_restricted', 'ri-shield-user-line']
  ]);

  public statusTranslation: Map<string, { percentage: number, label: string }> = new Map([
    ['rap-sanity-started', { percentage: 12.5, label: "Aufnahme bereinigen" }],
    ['rap-sanity-ended', { percentage: 25, label: "Aufnahme bereinigen" }],
    ['rap-process-started', { percentage: 37.5, label: "Aufnahme verarbeiten" }],
    ['rap-process-ended', { percentage: 50, label: "Aufnahme verarbeiten" }],
    ['rap-publish-started', { percentage: 62.5, label: "Aufnahme speichern" }],
    ['rap-post-publish-started', { percentage: 75, label: "Aufnahme veröffentlichen" }],
    ['rap-publish-ended', { percentage: 87.5, label: "Aufnahme speichern" }],
    ['rap-post-publish-ended', { percentage: 95, label: "Aufnahme veröffentlichen" }],
    ['uploadFinished', { percentage: 100, label: "Aufnahme ist bereit!" }]
  ])
  public roomIdToIsRunning: Map<string, boolean> = new Map();
  public mandantConfig: BBBMandantConf;
  constructor(
    private http: HttpClient,
    private socketService: SocketService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.socketService.socket.on('updateRecordingStatus', this.onUpdateRecordingStatus.bind(this));

    this.socketService.socket.on('updateRoomIsRunning', this.onRoomIsRunning.bind(this));

    this.socketService.socket.emit('getBbbMandantConfig', (config: { conf: BBBMandantConf }) => {
      this.mandantConfig = config.conf;
    });

    if (this.authService.getUserObject() != null) {
      this.getRecordingStatus();
    }
  }

  public async getRecordingStatus() {
    const response = await firstValueFrom(this.http.get<{ recordingsEvents: KickRecordingEvent[] }>('/api/recordingevent'));
    response.recordingsEvents.forEach(kickRecordingEvent => this.onUpdateRecordingStatus(kickRecordingEvent));
  }

  public onUpdateRecordingStatus(event: KickRecordingEvent) {
    if (!this.recordingEvents.has(event._id)) {
      this.recordingEvents.set(event._id, event);
    } else {
      const newPercentage = this.statusTranslation.get(event.event).percentage;
      const currentPercentage = this.statusTranslation.get(this.recordingEvents.get(event._id).event).percentage
      if (newPercentage > currentPercentage) {
        this.recordingEvents.set(event._id, event);
      }
    }
    if (this.recordingEvents.get(event._id).event == "uploadFinished") {
      setTimeout(async () => {
        this.recordingEvents.delete(event._id);
        await this.getRecordings();
        await this.getAllRecordings();
      }, 5000)
    }
  }

  public isDevelopHost() {
    return (
      window.location.host.startsWith('develop') ||
      window.location.hostname == 'localhost'
    );
  }

  public getUserObject() {
    return this.authService.getUserObject();
  }

  public getShort(): string {
    const user: BBBUser = this.authService.getUserObject();
    if (user?.short) {
      return user.short;
    } else {
      return location.hostname.split('.')[0];
    }
  }

  public async updateRoom(updateObject: BbbRoom): Promise<BbbRoom> {
    const url = '/api/rooms/' + updateObject._id;
    const index = this.rooms.findIndex((room) => room._id === updateObject._id);
    this.rooms[index] = { ...updateObject };
    const result = await firstValueFrom(this.http.put<BbbRoom>(url, updateObject));
    this.toastr.info("Raum gespeichert");
    return result;
  }

  public async createRoom(name: string): Promise<BbbRoom> {
    const url = '/api/rooms/';
    const newObject: BbbRoomCreate = {
      access: 'public',
      maxParticipants: 100,
      name: name,
      record: false,
      telco: false,
      requireModerator: false,
      welcome: '',
      password: this.generatePassword(),
    };
    const newRoom = await firstValueFrom(
      this.http.post<BbbRoom>(url, newObject),
    );
    if (newRoom) {
      if (this.isTechCheck()) {
        window.open(
          window.location.href +
          'join/' +
          newRoom._id +
          '?roomname=' +
          window.encodeURIComponent(newRoom.name),
        );
      } else {
        this.rooms.unshift(newRoom);
        this.updateIsRunningMap(newRoom._id);
      }
    }
    this.toastr.info("Raum erstellt");
    return newRoom;
  }

  public async deleteRoom(roomId: string): Promise<BbbRoom> {
    const url = '/api/rooms/' + roomId;
    const result = await firstValueFrom(this.http.delete<BbbRoom>(url));
    if (result) {
      const index = this.rooms.findIndex((r) => r._id === roomId);
      this.rooms.splice(index, 1);
    }
    this.toastr.info("Raum gelöscht");
    return result;
  }

  public async getRoomInfo(roomId: string): Promise<BbbRoom> {
    const url = '/api/rooms/' + roomId;
    const room = await firstValueFrom(this.http.get<BbbRoom>(url));
    this.updateIsRunningMap(room._id);
    return room;
  }

  public async getAllRooms(): Promise<BbbRoom[]> {
    const url = '/api/rooms';
    this.rooms = await firstValueFrom(this.http.get<BbbRoom[]>(url));
    this.roomsMap.clear();
    this.rooms.forEach(r => this.roomsMap.set(r._id, r));
    this.rooms.sort((a, b) => a.name.localeCompare(b.name));
    this.updateIsRunningMap();
    return this.rooms;
  }

  public async getRecordings(): Promise<Recording[]> {
    const url = '/api/recordings';
    this.recordings = await firstValueFrom(this.http.get<Recording[]>(url));
    return this.recordings;
  }

  public async getAllRecordings(): Promise<Recording[]> {
    if (!this.authService.getUserObject().permissions.includes('admin')) {
      return [];
    }
    const url = "/api/recordingsforadmin";
    this.allRecordings = await firstValueFrom(this.http.get<Recording[]>(url));
    return this.allRecordings;
  }

  public async getJoinInfo(roomId: string, short: string, guestId?: string): Promise<JoinInfo & { short: string }> {
    const url = '/api/joininfo/' + roomId + '/' + short + (guestId ? "/" + guestId : "");
    const joinInfo = await firstValueFrom(this.http.get<JoinInfo>(url))
    return { ...joinInfo, short: short };
  }

  public async validateGuest(roomId: string, short: string, guestId: string, password: string): Promise<boolean> {
    const url = '/api/validateguest/' + roomId + '/' + short + "/" + guestId;
    return await firstValueFrom(this.http.post<boolean>(url, { password }));
  }

  public async getRoomIsRunning(roomId: string): Promise<boolean> {
    const url = '/api/ismeetingrunning/' + roomId;
    const res: IsMeetingRunningResponse = await firstValueFrom(this.http.get<IsMeetingRunningResponse>(url));
    return res.running ?? false;
  }

  public async getRunningMeetings(): Promise<string[]> {
    const url = '/api/getrunningmeetings';
    const res: string[] = await firstValueFrom(this.http.get<string[]>(url));
    return res ?? [];
  }

  public async getPresentations(
    roomId: string,
  ): Promise<FsPortalFile<PresentationFileMetadata>[]> {
    const url = '/api/presentation/forRoom/' + roomId;
    return await firstValueFrom(
      this.http.get<FsPortalFile<PresentationFileMetadata>[]>(url),
    );
  }

  public async deletePresentation(presentationId: string): Promise<unknown> {
    const url = '/api/presentation/' + presentationId;
    const result = await firstValueFrom(this.http.delete<unknown>(url));
    this.toastr.info("Präsentation gelöscht");
    return result;
  }

  public async updatePresentation(presentationId: string, downloadable: boolean): Promise<unknown> {
    const url = '/api/presentation/updatepermission/' + presentationId;
    const body = {
      downloadable,
    };
    const result = await firstValueFrom(this.http.post<unknown>(url, body));
    this.toastr.info("Präsentation gespeichert");
    return result;
  }

  public async endRoom(roomId: string): Promise<EndResponse> {
    const url = '/api/endroom/' + roomId;
    const res = await firstValueFrom(this.http.get<EndResponse>(url));
    this.updateIsRunningMap(roomId);
    this.toastr.info("Raum beendet");
    return res;
  }

  public async joinUrl(
    roomId: string,
    short: string,
    name?: string,
    password?: string,
  ): Promise<(BbbRoom & { demo?: true }) | HttpErrorResponse> {
    let url = '/api/joinurl/' + roomId + "/" + short;
    if (name) {
      url += '?guestname=' + name;
    }
    if (password) {
      url += '&password=' + password;
    }
    return await firstValueFrom(this.http.get<BbbRoom & { demo?: true }>(url));
  }

  private async updateIsRunningMap(roomId?: string, value?: boolean) {
    if (roomId !== undefined) {
      if (value !== undefined) {
        this.roomIdToIsRunning.set(roomId, value);
      } else {
        const isRunning = await this.getRoomIsRunning(roomId);
        this.roomIdToIsRunning.set(roomId, isRunning);
      }
    } else {
      const runningMeetings = await this.getRunningMeetings();
      this.roomIdToIsRunning.clear()
      for (const meeting of runningMeetings) {
        this.roomIdToIsRunning.set(meeting, true);
      }
    }
  }

  public async getModeratorInvitationKey(): Promise<string> {
    const url = '/api/uuid';
    const result = await firstValueFrom(this.http.get<string>(url));
    return result['uuid'];
  }

  private generatePassword() {
    const length = 8;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let pw = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      pw += charset.charAt(Math.floor(Math.random() * n));
    }
    return pw;
  }

  private onRoomIsRunning(roomId: string, isRunning: boolean): void {
    this.updateIsRunningMap(roomId, isRunning);
  }

  public isTechCheck() {
    if (
      this.getUserObject().name == 'techcheck' &&
      this.getUserObject().email == 'techcheck' &&
      this.getShort() == 'kickdemo'
    ) {
      return true;
    }
    return false;
  }

  public updateBBBMandantConf(updatedConf: BBBMandantConf) {
    delete updatedConf._id;
    this.socketService.socket.emit('setBbbMandantConfig', updatedConf, (config: { conf: BBBMandantConf }) => {
      this.mandantConfig = config.conf;
    });
    this.toastr.info("Konfiguration gespeichert");
  }

  public async deleteAccountLogo() {
    const url = '/api/accountlogo';
    const result = await firstValueFrom(this.http.delete(url));
    this.toastr.info("Logo gelöscht");
    return result;
  }

  public async uploadAccountLogo(file, name) {
    const formData: FormData = new FormData();
    formData.append(name, file);
    const url = '/api/accountlogo';
    const result = await firstValueFrom(
      this.http.post(url, formData, { headers: this.getAuthHeaders() }),
    );
    this.toastr.info("Logo hochgeladen");
    return result;
  }

  public async searchRooms(searchString: string): Promise<BbbRoom[]> {
    const url = '/api/rooms/search/' + searchString;
    const rooms = await firstValueFrom(this.http.get<BbbRoom[]>(url));
    return rooms.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async addMyUserToRoom(roomId: string): Promise<BbbRoom> {
    const res = await firstValueFrom(this.http.get<BbbRoom>('/api/rooms/addMyUser/' + roomId, {}));
    await this.getAllRooms();
    return res;
  }

  public getAuthHeaders(): { token: string } {
    return {
      token: localStorage.getItem('bbbJWT'),
    };
  }

  public async deleteRecording(recId: string) {
    const res = await firstValueFrom(this.http.delete('/api/deleterecording/' + recId));
    return res;
  }

  public async changeRecDeleteOption(recId: string, autoDelete: boolean) {
    const res = await firstValueFrom(this.http.post('/api/recorddeleteoption/' + recId, { autoDelete }));
    return res;
  }

  public async addUserToAftershowPartyList(recId: string, newGuests: BbbGuest[]) {
    const res = await firstValueFrom(this.http.post('/api/recordingpartylist/' + recId + '/add', { users: newGuests }));
    return res;
  }

  public async updateUserInAftershowPartyList(recId: string, updatedGuest: BbbGuest) {
    const res = await firstValueFrom(this.http.post('/api/recordingpartylist/' + recId + "/update/" + updatedGuest.id, { user: updatedGuest }));
    return res;
  }

  public async deleteUserFromAftershowPartyList(recId: string, deletedGuests: BbbGuest[]) {
    const res = await firstValueFrom(this.http.post('/api/recordingpartylist/' + recId + "/delete", { users: deletedGuests }));
    return res;
  }

  public async checkRecGuestLogin(recId: string, userId: string, password: string, short: string): Promise<{ status: string }> {
    const res = await firstValueFrom(this.http.post<{ status: string }>('/api/recordinglogin/' + recId, { userId, password, short }));
    return res;
  }
  public async sendRecordingSuccess(recId: string, userId: string, password: string, short: string): Promise<{ status: string }> {
    const res = await firstValueFrom(this.http.post<{ status: string }>('/api/recordsuccess/' + recId, { userId, password, short, state: "success" }));
    return res;
  }
}