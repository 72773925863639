import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendancesListComponent } from './attendances-list/attendances-list.component';
import { BBBFrameComponent } from './bbbframe/bbbframe.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { JoinComponent } from './join/join.component';
import { LicenseAdminComponent } from './license-admin/license-admin.component';
import { LicenseManagementComponent } from './license-management/license-management.component';
import { LoginComponent } from './login/login.component';
import { PageBbbRoomsComponent } from './page-bbb-rooms/page-bbb-rooms.component';
import { RecordVideoPlaybackComponent } from './record-video-playback/record-video-playback.component';
import { RecordingListComponent } from './recording-list/recording-list.component';
import { BbbListResolver } from './shared/BbbListResolver';
import { FileSharingResolver } from './shared/FileSharingResolver';
import { JoinResolver } from './shared/JoinResolver';
import { LicenseResolver } from './shared/LicenseResolver';
import { LoginFromPortalResolver } from './shared/LoginFromPortalResolver';
import { AuthGuard } from './shared/auth.guard';
import { ragminGuard } from './shared/ragmin.guard';
import { TokenGuard } from './shared/token.guard';
import { UserResolver } from './shared/user.resolver';
import { UseradminGuard } from './shared/useradmin.guard';
import { SidekickFileShareComponent } from './sidekick-file-share/sidekick-file-share.component';
import { UserAdminComponent } from './user-admin/user-admin.component';

const routes: Routes = [
  {
    path: 'portallogin',
    component: JoinComponent,
    resolve: {
      data: LoginFromPortalResolver,
    },
  },
  {
    path: 'join/:id/:short',
    component: JoinComponent,
    resolve: {
      data: JoinResolver,
    },
  },
  {
    path: 'join/:id/:short/:guest_id',
    component: JoinComponent,
    resolve: {
      data: JoinResolver,
    },
  },
  {
    path: 'frames/:bbburl',
    component: BBBFrameComponent,
  },
  {
    path: 'recordings',
    canActivate: [AuthGuard],
    component: RecordingListComponent,
  },
  {
    path: 'playback/:input/:short/:id',
    component: RecordVideoPlaybackComponent,
  },
  {
    path: 'playback/:input/:short/:id/:guest',
    component: RecordVideoPlaybackComponent,
  },
  {
    path: 'useradmin',
    component: UserAdminComponent,
    canActivate: [UseradminGuard],
    resolve: {
      users: UserResolver,
    },
  },
  {
    path: 'attendances/:roomId',
    canActivate: [AuthGuard],
    component: AttendancesListComponent,
  },
  {
    path: 'license',
    canActivate: [UseradminGuard],
    component: LicenseAdminComponent,
    resolve: {
      users: UserResolver,
      licenses: LicenseResolver,
    },
  },
  {
    path: 'settings',
    canActivate: [UseradminGuard],
    component: GlobalSettingsComponent,
    resolve: {
      licenses: LicenseResolver,
    },
  },
  {
    path: 'demologin/:name/:email',
    canActivate: [TokenGuard],
    children: []
  },
  {
    path: 'filesharing/:sidekicktoken',
    component: SidekickFileShareComponent,
    resolve: {
      data: FileSharingResolver,
    },
  },
  {
    path: 'management',
    component: LicenseManagementComponent,
    canActivate: [ragminGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:redirect',
    component: LoginComponent
  },
  {
    pathMatch: 'full',
    path: '',
    canActivate: [AuthGuard],
    component: PageBbbRoomsComponent,
    resolve: {
      data: BbbListResolver,
    },
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
